import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Dashboard from '../Views/Dashboard/Dashboard'
import Datatable from './Datatable'
import Form from './Form'
import Header from './Header'
import Sidebar from './Sidebar'

export default function Main(){
    return(
        <div class="main-wrapper">
            <Header />
            <Sidebar />
            <Outlet />

        </div>
    )
}