import React, { useEffect, useState } from 'react';
import TermSOfServiceList from './TermsOfServiceList';
import PrivacyList from './PrivacyList';
import Header from './Header';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Sidebar from './Sidebar';
import HttpClient from '../utils/HttpClient';
import toast, { Toaster } from 'react-hot-toast';
const AboutUs = () => {
  const [comments, setComments] = useState('');
  console.log('comments', comments);
  const savedTerms = async () => {
    const res = await HttpClient.requestData('admin/view-terms-service', 'GET');
    console.log('privres', res.data);
    if (res && res.status) {
      setComments(res.data[0].desc);
    }
  };
  useEffect(() => {
    savedTerms();
  }, []);

  const handleDetails = async e => {
    e.preventDefault();
    const sendData = {
      desc: comments,
    };
    const res = await HttpClient.requestData('admin/add-terms-service', 'POST', sendData);
    console.log('res', res);
    if (res && res.status) {
      toast.success(res.message);
    } else {
      toast.success(res.message);
    }
  };

  return (
    <div>
      <Sidebar />
      <div class="page-wrapper">
        <div class="content container-fluid">
          <p>View About Us</p>
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <p>About Us</p>
                    <form method="post" enctype="multipart/form-data">
                      <CKEditor
                        editor={ClassicEditor}
                        data={comments}
                        onReady={editor => {
                          console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          console.log({ event, editor, data });
                          setComments(data);
                        }}
                        onBlur={(event, editor) => {
                          console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log('Focus.', editor);
                        }}
                      />
                    </form>
                    <div>
                      <button onClick={handleDetails} className="btn btn-secondary mt-2">
                        Update
                      </button>
                      <Toaster />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {comments.map((comment, index) => (
        <div style={{ marginLeft: '20rem', marginTop: '10rem' }}>
          <TermSOfServiceList
            key={index}
            comment={comment}
            onEdit={updatedComment => handleEditComment(index, updatedComment)}
            onDelete={() => handleDeleteComment(index)}
          />
        </div>
      ))} */}
    </div>
  );
};

export default AboutUs;
