import React, { useEffect, useState } from 'react';
import HttpClient from '../utils/HttpClient';
import { reactLocalStorage } from 'reactjs-localstorage';
import moment from 'moment';
import { click } from '@testing-library/user-event/dist/click';
import Modal from './Modal';
import DataTable from 'react-data-table-component';

export default function Datatable() {
  const [proivderlist, setProviderList] = useState([]);
  const [open, setOpen] = useState(false);
  const [modaldata, setmodaldata] = useState({});
  const [data, setData] = useState([]);
  const columns = [
    {
      name: 'Sl No',
      selector: row => row.sl_no,
    },
    {
      name: 'First Name',
      selector: row => row.firstName,
    },
    {
      name: 'Last Name',
      selector: row => row.lastName,
    },
    {
      name: 'Email',
      selector: row => row.email,
    },

    {
      name: 'Mobile Number',
      selector: row => row.mobileNumber,
    },
    // {
    //   name: 'Notification Status',
    //   selector: row => row.notificationStatus,
    // },

    {
      name: 'User Type',
      selector: row => row.userType,
    },
    {
      name: 'Zipcode',
      selector: row => row.zipcode,
    },
    {
      name: 'Id',
      selector: row => row.id,
    },
  ];
  const datas = [
    {
      id: 1,
      title: 'Beetlejuice',
      year: '1988',
    },
    {
      id: 2,
      title: 'Ghostbusters',
      year: '1984',
    },
  ];

  console.log('proivderlistNew', proivderlist);
  const serviceProvider = async () => {
    // const servicereqlist = await HttpClient.requestData('task-service-requester', 'GET',{});
    const res = await HttpClient.requestData('admin/get-provider', 'POST', {});
    console.log('providerres', res);
    if (res && res.status) {
      setProviderList(res.data);
      let arr = [];
      res.data.forEach((item, i) => {
        arr.push({
          sl_no: i + 1,

          email: item.email,
          firstName: item.firstName,

          lastName: item.lastName,
          mobileNumber: item.mobileNumber,
          // notificationStatus: item.notificationStatus,
          userType: item.userType,
          zipcode: item.zipCode,
          id: item._id,
        });
      });
      console.log('arrr', arr);
      setData(arr);
    }
  };

  // const handleSingleProvider = data => {
  //   console.log('firsdatat', data);
  //   setOpen(!open);
  //   setmodaldata(data);
  //   console.log('modaldata', modaldata);
  // };

  useEffect(() => {
    serviceProvider();
  }, []);

  return (
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col">View Provider Details</div>
            <div class="col-auto">
              {/* <a href="add_household_profile.php" class="btn btn-primary">
                ADD
              </a> */}
              {/* <i class="fas fa-plus"></i> */}
            </div>
          </div>
        </div>
        {/* {globeDetails && globeDetails.map((ele)=>(
          <div key={ele._id}>{ele.city}</div>
        ))} */}
        <DataTable columns={columns} data={data} pagination />;
      </div>
    </div>
    // <div class="page-wrapper">
    //   <div class="content container-fluid">
    //     <div class="page-header">
    //       <div class="row">
    //         <div class="col">view from</div>

    //         <div class="col-auto">
    //           <a href="add_household_profile.php" class="btn btn-primary">
    //             ADD
    //           </a>
    //           <i class="fas fa-plus"></i>
    //         </div>
    //       </div>
    //     </div>
    //     <div class="row">
    //       <div class="col-md-12">
    //         <div class="card">
    //           <div class="card-body">
    //             <div class="table-responsive">
    //               <table id="example" class="table table-striped table-bordered" style={{ width: '100%' }}>
    //                 <thead>
    //                   <tr align="center">
    //                     <div>
    //                       {open ? (
    //                         <div
    //                           style={{
    //                             height: '300px',
    //                             width: '600px',
    //                             border: '1px solid black',
    //                             position: 'fixed',
    //                             backgroundColor: 'white',
    //                             color: 'black',
    //                           }}
    //                         >
    //                           City:{modaldata.city}
    //                           <br></br>
    //                           createdOn: {modaldata.createdOn} <br></br>
    //                           Email: {modaldata.email}
    //                           <br></br>
    //                           FirstName: {modaldata.firstName}
    //                           <br></br>
    //                           {modaldata.gender}
    //                           <br></br>
    //                           {modaldata.lastName}
    //                           <td>0,0</td>
    //                           <td>{modaldata.mobileNumber}</td>
    //                           <td>{JSON.stringify(modaldata.notificationStatus)}</td>
    //                           <td>{modaldata.state}</td>
    //                           <td>{modaldata.userType}</td>
    //                           <td>{modaldata.zipCode}</td>
    //                           <td>{modaldata._id}</td>
    //                         </div>
    //                       ) : (
    //                         ''
    //                       )}
    //                     </div>
    //                     <th>Sl.No.</th>
    //                     <th>City</th>
    //                     <th>Created On</th>
    //                     {/* <th>Device Token</th> */}
    //                     <th>Email</th>
    //                     <th>First Name</th>
    //                     <th>Gender</th>
    //                     <th>Last Name</th>
    //                     <th>Location</th>
    //                     <th>Mobile Number</th>
    //                     <th>notificationStatus</th>
    //                     <th>State</th>
    //                     <th>UserType</th>
    //                     <th>ZipCode</th>
    //                     <th>Id</th>
    //                   </tr>
    //                 </thead>
    //                 <tbody>
    //                   {proivderlist.map((item, i) => (
    //                     <tr align="center" onClick={() => handleSingleProvider(item)}>
    //                       <td>{i + 1}</td>
    //                       <td>{item.city} </td>
    //                       <td>{moment(item.createdOn).format('LL')}</td>
    //                       {/* <td>{item.deviceToken}</td> */}
    //                       <td>{item.email}</td>
    //                       <td>{item.firstName}</td>
    //                       <td>{item.gender}</td>
    //                       <td>{item.lastName}</td>
    //                       <td>0,0</td>
    //                       <td>{item.mobileNumber}</td>
    //                       <td>{JSON.stringify(item.notificationStatus)}</td>
    //                       <td>{item.state}</td>
    //                       <td>{item.userType}</td>
    //                       <td>{item.zipCode}</td>
    //                       <td>{item._id}</td>
    //                       <td>
    //                         <a
    //                           href="edit_household_profile.php?id=<?php echo base64_encode($id); ?>"
    //                           class="btn btn-primary"
    //                         >
    //                           Edit
    //                         </a>
    //                       </td>
    //                       <td>
    //                         <a href="manage_household.php?id=<?php echo base64_encode($id);?>">
    //                           <i aria-hidden="true" class="fa fa-trash btn-lg"></i>
    //                         </a>
    //                       </td>
    //                     </tr>
    //                   ))}
    //                 </tbody>
    //               </table>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
