import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import HttpClient from '../utils/HttpClient';

const Investor = () => {
    const [data, setData] = useState([]);

    const columns = [
        {
            name: 'SL NO.',
            selector: row => row.sl_no,
        }, {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'User-Type',
            selector: row => row.userType,
        },
    ]

    const globaluserdetailslist = async () => {
        const res = await HttpClient.requestData('admin/allinvestor', 'GET');
        if (res && res?.status) {
            let arr = res?.data?.reverse()?.map((item, ind) => {
                console.log("df4g1f", item)
                return {
                    sl_no: ind + 1,
                    email: item?.email,
                    userType: item?.userType,
                }
            });
            setData(arr);
        }
    };

    useEffect(() => {
        globaluserdetailslist();
    }, []);
    return (
        <div class="page-wrapper">
            <div class="content container-fluid">
                <div class="page-header">
                    <div class="row">
                        <div class="col">View Investor User Details</div>
                        <div class="col-auto">
                            {/* <a href="add_household_profile.php" class="btn btn-primary">
                ADD
              </a> */}
                            {/* <i class="fas fa-plus"></i> */}
                        </div>
                    </div>
                </div>
                <DataTable columns={columns} data={data} pagination />
            </div>
        </div>
    )
}

export default Investor