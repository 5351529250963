import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import HttpClient from '../utils/HttpClient';

const InvitedDetails = () => {
  const [invitedUser, setInvitedUser] = useState([]);

  useEffect(() => {
    InvitedDetails();
  }, []);

  const InvitedDetails = async () => {
    let result = await HttpClient.requestData('admin/invited-user', 'GET');
    console.log('Results1', result);
    if (result && result.status) {
      let arr = result.data.map((item, index) => {
        return {
          sl: index + 1,
          userType: item?.userType,
          firstName: item?.firstName,
          lastName: item?.lastName,
          gender: item?.gender,
          email: item?.email,
          city: item?.city,
          mobileNumber: item?.mobileNumber,
          notificationStatus: JSON.stringify(item?.notificationStatus),
          state: item?.state,
          zipCode: item?.zipCode,
          invitationCode: item?.invitationCode,
          invitationSendFrom: item?.invitationSendFrom,
          ownCode: item?.ownCode,
        };
      });
      setInvitedUser(arr ? arr : []);
    }
  };

  const columns = [
    {
      name: 'Sl No',
      selector: row => row.sl,
    },
    {
      name: 'UserType',
      selector: row => row.userType,
    },
    {
      name: 'FirstName',
      selector: row => row.firstName,
    },
    {
      name: 'LastName',
      selector: row => row.lastName,
    },

    {
      name: 'invitationCode',
      selector: row => row.invitationCode,
    },
    {
      name: 'invitationSendFrom',
      selector: row => row.invitationSendFrom,
    },
    {
      name: 'ownCode',
      selector: row => row.ownCode,
    },

    {
      name: 'Gender',
      selector: row => row.gender,
    },
    {
      name: 'Email',
      selector: row => row.email,
    },
    {
      name: 'City',
      selector: row => row.city,
    },
    {
      name: 'Mobile Number',
      selector: row => row.mobileNumber,
    },
    {
      name: 'NotificationStatus',
      selector: row => row.notificationStatus,
    },
    {
      name: 'State',
      selector: row => row.state,
    },
    {
      name: 'zipCode',
      selector: row => row.zipCode,
    },
  ];

  return (
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col">View Invited User Details</div>
          </div>
          <DataTable data={invitedUser} columns={columns} pagination />
        </div>
      </div>
    </div>
  );
};

export default InvitedDetails;
