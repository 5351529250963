import React from 'react'
import Sidebar from './Sidebar'
import toast from 'react-hot-toast';
import HttpClient from '../utils/HttpClient';

const UploadCSV = () => {

    // >>>>Handle CSV Upload<<<<
    const handleImage = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        if (file.size > 5 * 1024 * 1024) {
            toast.error("Image size must be less than 5 MB");
            return;
        }

        let formData = new FormData();
        formData.append("file", file);
        // setLoading(true);
        const res = await HttpClient.fileUplode("admin/upload-csv", "POST", formData);
        // toast.success("CSV Uploaded Successfully");
        if (res && res.status === 200) {
            // setLoading(false);
            toast.success(res?.message || "CSV Uploaded Successfully");
        } else {
            toast.error(res?.message || "Something Went Wrong");
        }
    };
    return (
        <div>
            <Sidebar />
            <div class="page-wrapper">
                <div class="content container-fluid">
                    <p>Upload CSV</p>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <form>
                                            <div className='d-flex'>
                                                <div className="form-group mb-3 w-50">
                                                    <label htmlFor="image">CSV</label>
                                                    <input
                                                        type="file"
                                                        id="image"
                                                        name="image"
                                                        className="form-control"
                                                        accept="image/*"
                                                        onChange={handleImage}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default UploadCSV