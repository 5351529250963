import React, { useEffect, useState } from 'react';
import HttpClient from '../utils/HttpClient';
import DataTable from 'react-data-table-component';
import moment from 'moment';

const ContactUsMgmt= () => {
  const [data, setData] = useState([]);
  const [globeDetails, setGlobeDetails] = useState([]);

  const columns = [
    {
      name: 'Sl No.',
      selector: row => row.userType,
    },
    {
      name: 'User Name',
      selector: row => row.registeredDate,
    },
    {
      name: 'Contact Email',
      selector: row => row.lastPaidDate,
    },
    {
      name: 'User Type',
      selector: row => row.nextPaymentDue,
    },
    {
      name: 'Subject',
      selector: row => row.nextPaymentDue,
    },
    {
      name: 'Message',
      selector: row => row.nextPaymentDue,
    },
  ];

  const globaluserdetailslist = async () => {
    const res = await HttpClient.requestData('admin/get-allUser', 'POST', {});
    // console.log('res', res);
    // if(res && res.status){
    //   setGlobeDetails(res)
    //   console.log(glo)
    // }
    console.log('globeDetails', res);
    if (res) {
      setGlobeDetails(res.data);
      let arr = [];
      res.data.forEach((item, i) => {
        arr.push({
          sl_no: i + 1,
          city: item.city,
          created_on: moment(item.createdOn).format('LL'),
          email: item.email,
          firstName: item.firstName,
          gender: item.gender,
          lastName: item.lastName,
          mobileNumber: item.mobileNumber,
          // notificationStatus: item.notificationStatus,
          state: item.state,
          // userType: item.userType,
          zipcode: item.zipCode,
          id: item._id,
        });
      });
      console.log('arrr', arr);
      setData(arr);
    }
  };

  useEffect(() => {
    globaluserdetailslist();
  }, []);
  return (
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col">View Global User Details</div>
            <div class="col-auto">
              {/* <a href="add_household_profile.php" class="btn btn-primary">
                ADD
              </a> */}
              {/* <i class="fas fa-plus"></i> */}
            </div>
          </div>
        </div>
        {/* {globeDetails && globeDetails.map((ele)=>(
          <div key={ele._id}>{ele.city}</div>
        ))} */}
        <DataTable columns={columns} data={data} pagination />;
      </div>
    </div>
  );
};

export default ContactUsMgmt;
