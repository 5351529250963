import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Form from './Layout/Form';
import Datatable from './Layout/Datatable';
import Dashboard from './Views/Dashboard/Dashboard';
import Main from './Layout/Main';
import Login from './Views/Auth/login';
import Password from './Views/Password';
import MyProfile from './Views/MyProfile/MyProfile';
import PrivateRoutes from './PrivateRoutes';
import { useAuth } from './Context/UserContext';
import { useEffect } from 'react';
import GeneralReport from './Layout/GeneralReport';
import GlobalUserDetails from './Layout/GlobalUserDetails';
import FeesManagement from './Layout/FeesManagement';
import RequesterDetails from './Layout/RequesterDetails';
import InvitedDetails from './Layout/InvitedDetails';
import CategoryMgmt from './Layout/CategoryMgmt';
import Privacy from './Layout/Privacy';
import SubCategoryMgmt from './Layout/SubCategoryMgmt';
import TermsOfService from './Layout/TermsOfService';
import ContactUsMgmt from './Layout/ContactUsMgmt';
import SUbUserDetail from './Layout/SubUserDetail';
import BuUserDetail from './Layout/BuUserDetail';
import PuUserDetail from './Layout/PuUserDetail';
import PaidInvitedUsers from './Layout/PaidInvitedUsers';
import SubsCriptionDetails from './Layout/SubscriptionDetails';
import MasterSubscriptionView from './Layout/MasterSubscriptionView';
import NotificationManagement from './Layout/NotificationManagement';
import AboutUs from './Layout/AboutUs';
import UploadCSV from './Layout/UploadCSV';
import Investor from './Layout/Investor';
import BookKeeper from './Layout/BookKeeper';
import AllUsers from './Layout/AllUsers';
function App() {
  const { fetchUserData } = useAuth();

  useEffect(() => {
    fetchUserData();
  }, []);
  return (
    <Routes>

      <Route element={<PrivateRoutes />}>
        <Route element={<Main />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/global-user-details" element={<GlobalUserDetails />} />
          <Route path="/sub-user-detail" element={<SUbUserDetail />} />
          <Route path="/bu-user-detail" element={<BuUserDetail />} />
          <Route path="/pu-user-detail" element={<PuUserDetail />} />
          <Route path="paid-invited-users" element={<PaidInvitedUsers />} />
          <Route path="subscription-details" element={<SubsCriptionDetails />} />
          <Route path="master-subscription-view" element={<MasterSubscriptionView />} />
          <Route path="notification-management" element={<NotificationManagement />} />
          <Route path="/requester-details" element={<RequesterDetails />} />
          <Route path="/invited-details" element={<InvitedDetails />} />
          <Route path="/form" element={<Form />} />
          <Route path="/generalreport" element={<GeneralReport />} />
          <Route path="/categoryManagement" element={<CategoryMgmt />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          {/* <Route path="/subCategoryManagement" element={<SubCategoryMgmt />} /> */}
          <Route path="/contact-us-mgmt" element={<ContactUsMgmt />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/investor-detail" element={<Investor />} />
          <Route path="/book-keeper-detail" element={<BookKeeper />} />
          <Route path="/all-users-detail" element={<AllUsers />} />

          <Route path="/provider-details" element={<Datatable />} />
          <Route path="/upload-csv" element={<UploadCSV />} />
        </Route>
      </Route>

      <Route path="/profile" element={<MyProfile />} />
      <Route path="/privacy" element={<Privacy />} />

      <Route path="/login" element={<Login />} />
      <Route path="/reset-password/:id" element={<Password />} />
    </Routes>
  );
}

export default App;
