import React, { useState } from 'react';
import logo from "../assets/favicon.png"
export default function Sidebar() {
  return (
    <div class="sidebar" id="sidebar">
      <div class="sidebar-logo">
        <a href="/">
          <img src={logo} class="img-fluid" alt="" style={{ marginLeft: "5rem" }} />
          <h3>Fisibility Admin</h3>
        </a>
      </div>
      <div class="sidebar-inner slimscroll">
        <div id="sidebar-menu" class="sidebar-menu" style={{ height: "450px", overflow: "auto" }}>
          <ul>
            {/* <li class="active">
              <a href="/">
                <i class="fa fa-server"></i> <span>Dashboard</span>
              </a>
            </li> */}

            {/* <li class="active">
              <a href="/global-user-details">
                <i class="fa fa-server"></i> <span>Global User Detail</span>
              </a>
            </li> */}

            {/* <li class="active">
              <a href="/sub-user-detail">
                <i class="fa fa-server"></i> <span>Sub User Detail</span>
              </a>
            </li> */}

            <li class="active">
              <a href="/all-users-detail">
                <i class="fa fa-server"></i> <span>All User Detail</span>
              </a>
            </li>

            <li class="active">
              <a href="/bu-user-detail">
                <i class="fa fa-server"></i> <span>BU User Detail</span>
              </a>
            </li>

            <li class="active">
              <a href="/pu-user-detail">
                <i class="fa fa-server"></i> <span>PU User Detail</span>
              </a>
            </li>

            <li class="active">
              <a href="/investor-detail">
                <i class="fa fa-server"></i> <span>Investor Detail</span>
              </a>
            </li>

            <li class="active">
              <a href="/book-keeper-detail">
                <i class="fa fa-server"></i> <span>BookKeeper Detail</span>
              </a>
            </li>

            {/* <li class="active">
              <a href="/paid-invited-users">
                <i class="fa fa-server"></i> <span>Paid invited users</span>
              </a>
            </li> */}
            {/* <li class="active">
              <a href="/subscription-details">
                <i class="fa fa-server"></i> <span>Subscription Details</span>
              </a>
            </li> */}
            {/* <li class="active">
              <a href="/master-subscription-view">
                <i class="fa fa-server"></i> <span>Master Subscription View</span>
              </a>
            </li> */}
            {/* <li class="active">
              <a href="/notification-management">
                <i class="fa fa-server"></i> <span>Notification Management</span>
              </a>
            </li> */}
            {/* <li class="active">
              <a href="/contact-us-mgmt">
                <i class="fa fa-server"></i> <span>Contact Us Management</span>
              </a>
            </li> */}
            {/* <li class="active">
              <a href="/subCategoryManagement">
                <i class="fa fa-server"></i> <span>SubCategory Mgmt</span>
              </a>
            </li> */}

            {/* <li class="active">
              <a href="/privacy">
                <i class="fa fa-server"></i> <span>Privacy Management</span>
              </a>
            </li> */}
            {/* <li class="active">
              <a href="/terms-of-service">
                <i class="fa fa-server"></i> <span>Terms of Service</span>
              </a>
            </li> */}
            {/* <li class="active">
              <a href="/about-us">
                <i class="fa fa-server"></i> <span>About Us</span>
              </a>
            </li> */}
            <li class="active">
              <a href="/upload-csv">
                <i class="fa fa-server"></i> <span>Upload CSV</span>
              </a>
            </li>

          </ul>
        </div>
      </div>
    </div>
  );
}
