import React, { useEffect, useState } from 'react';
import Header from '../../Layout/Header';
import Sidebar from '../../Layout/Sidebar';
import HttpClient from '../../utils/HttpClient';
import { useNavigate } from 'react-router-dom';

export default function Dashboard() {
  const navigate = useNavigate()
  const [allUserData, setAllUserData] = useState([]);
  const [allBUuserData, setAllBUuserData] = useState([]);
  const [allPUuserData, setAllPUuserData] = useState([]);
  const [allInvestorData, setAllInvestorData] = useState([]);
  const [allBookKeeperData, setAllBookKeeperData] = useState([]);

  const [serviceRequestor, setserviceRequestor] = useState({});
  const [provider, setProvider] = useState({});
  const [revenue, setRevenue] = useState({});
  const [task, setTask] = useState({});
  const [invitedUsers, setInvitedUsers] = useState({});
  console.log('serviceRequestor', serviceRequestor);

  const inviteDetails = async () => {
    const res = await HttpClient.requestData('admin/invited-user', 'GET');
    console.log('invitedres', res);
  };
  useEffect(() => {
    inviteDetails();
  }, []);

  const dashboard = async () => {
    const dashboardResult = await HttpClient.requestData('admin/dashboard-stat', 'GET', {});
    // console.log('dashboardResult', dashboardResult.data);

    if (dashboardResult && dashboardResult.status) {
      setserviceRequestor(dashboardResult);
      // setservicereqlist(dashboard)
      setProvider(dashboardResult ? dashboardResult : {});
      setRevenue(dashboardResult ? dashboardResult : {});
      setTask(dashboardResult ? dashboardResult : {});
      console.log(provider);
    } else {
      setProvider({});
      setRevenue({});
      setTask({});
    }
    // console.log('serviceRequestor', serviceRequestor);
    // setProvider(dashboardResult.data.totalRequester);
    // console.log('ServiceRequestor is', serviceRequestor);
  };

  const globaluserdetailslist = async () => {
    const res = await HttpClient.requestData('admin/allusers', 'GET');
    if (res && res?.status) {
      setAllUserData(res?.data);
    }
  };

  const globalBUuserdetailslist = async () => {
    const res = await HttpClient.requestData('admin/allBuUser', 'GET');
    if (res && res?.status) {
      setAllBUuserData(res?.data);
    }
  };

  const globalPUuserdetailslist = async () => {
    const res = await HttpClient.requestData('admin/allPersonalUser', 'GET');
    if (res && res?.status) {
      setAllPUuserData(res?.data);
    }
  };

  const globalInvestordetailslist = async () => {
    const res = await HttpClient.requestData('admin/allinvestor', 'GET');
    if (res && res?.status) {
      setAllInvestorData(res?.data);
    }
  };

  const globalBookKeeperdetailslist = async () => {
    const res = await HttpClient.requestData('admin/allBooks', 'GET');
    if (res && res?.status) {
      setAllBookKeeperData(res?.data);
    }
  };

  useEffect(() => {
    globaluserdetailslist()
    globalBUuserdetailslist()
    globalPUuserdetailslist()
    globalInvestordetailslist()
    globalBookKeeperdetailslist()
    dashboard();
  }, []);
  return (
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col-12"></div>
          </div>
        </div>
        <div class="row">

          <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body" style={{ cursor: "pointer" }} onClick={() => navigate('/all-users-detail')}>
                <h6 class="text-muted total_USer">ALL USERS</h6>
                <div class="dash-widget-header d-block">
                  <div class="dash-widget-info m-0">
                    <h3 id="household_response_div">{allUserData?.length} </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body" style={{ cursor: "pointer" }} onClick={() => navigate('/bu-user-detail')}>
                <h6 class="text-muted total_USer">BUSINESS USERS</h6>
                <div class="dash-widget-header d-block">
                  <div class="dash-widget-info m-0">
                    <h3 id="household_response_div">{allBUuserData?.length} </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body" style={{ cursor: "pointer" }} onClick={() => navigate('/pu-user-detail')}>
                <h6 class="text-muted total_USer">PERSONEL USERS</h6>
                <div class="dash-widget-header d-block">
                  <div class="dash-widget-info m-0">
                    <h3 id="household_response_div">{allPUuserData?.length} </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body" style={{ cursor: "pointer" }} onClick={() => navigate('/investor-detail')}>
                <h6 class="text-muted total_USer">All INVESTOR</h6>
                <div class="dash-widget-header d-block">
                  <div class="dash-widget-info m-0">
                    <h3 id="household_response_div">{allInvestorData?.length} </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body" style={{ cursor: "pointer" }} onClick={() => navigate('/book-keeper-detail')}>
                <h6 class="text-muted total_USer">All BOOK-KEEPER</h6>
                <div class="dash-widget-header d-block">
                  <div class="dash-widget-info m-0">
                    <h3 id="household_response_div">{allBookKeeperData?.length} </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <h6 class="text-muted total_USer">TOTAL {serviceRequestor.totalRequester} OF REQUESTER USERS </h6>
                <div class="dash-widget-header d-block">
                  <div class="dash-widget-info m-0">
                    <h3 id="household_response_div">{serviceRequestor.totalRequester}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <h6 class="text-muted total_USer">TOTAL {provider?.totalProvider && provider?.totalProvider} OF PROVIDER USERS</h6>
                <div class="dash-widget-header">
                  <div class="dash-widget-info">
                    <h3>{provider.totalProvider && provider.totalProvider}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <h6 class="text-muted total_USer">TOTAL {provider?.openTask && provider.openTask} OF OPEN TASKS</h6>
                <div class="dash-widget-header">
                  <div class="dash-widget-info">
                    <h3>{provider?.openTask && provider.openTask}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <h6 class="text-muted total_USer">TOTAL {provider.userInvitation && provider.userInvitation} OF SENT USER INVITATIONS</h6>
                <div class="dash-widget-header">
                  <div class="dash-widget-info">
                    <h3>{provider.userInvitation && provider.userInvitation}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <h6 class="text-muted total_USer">TOTAL {provider.starRatedRequester && provider.starRatedRequester} OF 1-3 STAR RATED REQUESTER</h6>
                <div class="dash-widget-header">
                  <div class="dash-widget-info">
                    <h3>{provider.starRatedRequester && provider.starRatedRequester}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <h6 class="text-muted total_USer">TOTAL {provider.totalUnapprovedProvider && provider.totalUnapprovedProvider} OF UNAPPROVED PROVIDERS</h6>
                <div class="dash-widget-header">
                  <div class="dash-widget-info">
                    <h3>{provider.totalUnapprovedProvider && provider.totalUnapprovedProvider}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <h6 class="text-muted total_USer">TOTAL {provider.completedTask && provider.completedTask} OF COMPLETED TASKS</h6>
                <div class="dash-widget-header">
                  <div class="dash-widget-info">
                    <h3>{provider.completedTask && provider.completedTask}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <h6 class="text-muted total_USer">TOTAL 15% OF FEE RECEIVED</h6>
                <div class="dash-widget-header">
                  <div class="dash-widget-info">
                    <h3> {provider.totalFifteenFeeRecieve && provider.totalFifteenFeeRecieve}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <h6 class="text-muted total_USer">TOTAL AMOUNT PAID BY REQUESTERS</h6>
                <div class="dash-widget-header">
                  <div class="dash-widget-info">
                    <h3>{provider.totalAmountPaidByRequester && provider.totalAmountPaidByRequester}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <h6 class="text-muted total_USer">TOTAL AMOUNT RECEIVED BY PROVIDERS</h6>
                <div class="dash-widget-header">
                  <div class="dash-widget-info">
                    <h3>{provider.providerFeeReceived && provider.providerFeeReceived}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <h6 class="text-muted total_USer">Number of Service Requestors</h6>
                <div class="dash-widget-header d-block">
                  <div class="dash-widget-info m-0">
                    <h3 id="household_response_div">{serviceRequestor.totalRequester}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <h6 class="text-muted total_USer">Number of Service Providers</h6>
                <div class="dash-widget-header">
                  <div class="dash-widget-info">
                    <h3>{provider.totalProvider}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <h6 class="text-muted total_USer">TOTAL PROCESSED REVENUE</h6>
                <div class="dash-widget-header">
                  <div class="dash-widget-info">
                    <h3 id="household_response_div">{revenue.totalProcessedRevenue && revenue.totalProcessedRevenue}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div class="col-xl-6 col-sm-6 col-12">
            <div class="card">
              <div class="card-body">
                <h6 class="text-muted total_USer">Number of Tasks (jobs) Posted</h6>
                <div class="dash-widget-header">
                  <div class="dash-widget-info">
                    <h3 id="household_response_div">{task.openTask}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div class="row">
          <div class="col-md-6">
            <div id="chartContainer" style={{ height: '200px', width: '100%' }}></div>
          </div>
          <div class="col-md-6">
            <div id="chartContainer1" style={{ height: '200px', width: '100%' }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
