import React from 'react';
import { useAuth } from './Context/UserContext';
import { Navigate, Outlet } from 'react-router-dom';

function PrivateRoutes() {

  const userToken = localStorage.getItem("userData");

  if (userToken) {
    // console.log("privvrr", userToken);
    return <Outlet />
  } else {
    return <Navigate to={'/login'} />
  }
}

export default PrivateRoutes;
