// import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import HttpClient from '../../utils/HttpClient';
import { toast } from 'react-hot-toast';
import { useEffect } from 'react';

export default function MyProfile() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [userName, setUserName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [icon, setIcon] = useState(true);

  const { id } = useParams();
  console.log('ID', id);

  useEffect(() => {
    userData();
  }, []);

  const userData = async () => {
    let result = await HttpClient.requestData('admin/view', 'GET');
    console.log('ResultProfile', result);
    if (result && result.success) {
      console.log('jjjjj', result.data.name, result.data.email, result.data.phoneNo);
      setUserName(result.data.name);
      setEmail(result.data.email);
      setPhoneNumber(result.data.phoneNo);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();
    let data = {
      oldPassword: currentPassword,
      password: newPassword,
    };
    // ${JSON.parse(localStorage.getItem('userData'))._id}
    try {
      let result = await HttpClient.requestData('admin/password-change', 'PUT', data);

      if (result && result?.status) {
        toast.success(result?.message, {
          style: {
            borderRadius: '10px',
            background: '#8297cd',
            color: 'FFF',
          },
        });
      } else {
        toast.error(result?.message);
      }
      setCurrentPassword('');
      setNewPassword('');
      console.log('Data ', data);
    } catch (error) {
      console.log('Catch', error);
    }
  };
  const onEdit = async e => {
    e.preventDefault();
    let data = {
      name: userName,
      email: email,
      phone: phoneNumber,
    };
    let result = await HttpClient.requestData('admin/update', 'PUT', data);
    console.log('Result', result);
    if (result && result?.success) {
      toast.success(result?.message, {
        style: {
          borderRadius: '10px',
          background: '#8297cd',
          color: 'FFF',
        },
      });
    } else {
      toast.error(result?.message);
    }
  };

  return (
    <>
      {/* <div class="page-wrapper"> */}
      {/* <div class="content container-fluid"> */}
      <div class="row">
        <div class="col-xl-12 offset-xl-12">
          <div class="page-header">
            <div class="row">
              <div class="col-sm-12" style={{ textAlign: 'center', marginTop: '22px' }}>
                {/* <h3 class="page-title" style={{ textAlign: 'center', marginTop: '22px' }}>
                  Profile
                </h3> */}
                {icon ? (
                  <svg
                    style={{ height: '75px' }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    style={{ height: '75px' }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M15.75 1.5a6.75 6.75 0 00-6.651 7.906c.067.39-.032.717-.221.906l-6.5 6.499a3 3 0 00-.878 2.121v2.818c0 .414.336.75.75.75H6a.75.75 0 00.75-.75v-1.5h1.5A.75.75 0 009 19.5V18h1.5a.75.75 0 00.53-.22l2.658-2.658c.19-.189.517-.288.906-.22A6.75 6.75 0 1015.75 1.5zm0 3a.75.75 0 000 1.5A2.25 2.25 0 0118 8.25a.75.75 0 001.5 0 3.75 3.75 0 00-3.75-3.75z"
                      clip-rule="evenodd"
                    />
                  </svg>
                )}

                {/* <h3 style={{ textAlign: 'center' }}>Profile</h3> */}
              </div>
            </div>
          </div>

          <div class="card" style={{ width: '31%', marginLeft: 'auto', marginRight: 'auto' }}>
            <div class="card-body profile-menu" style={{ borderRadius: '12px' }}>
              <ul class="nav nav-tabs nav-tabs-solid" role="tablist">
                <li class="nav-item home_tab" onClick={() => setIcon(true)}>
                  <a
                    class="nav-link active"
                    data-toggle="tab"
                    href="#profile_settings"
                    role="tab"
                    aria-selected="false"
                  >
                    Profile Settings
                  </a>
                </li>
                <li class="nav-item home_add" onClick={() => setIcon(false)}>
                  <a class="nav-link" data-toggle="tab" href="#change_password" role="tab" aria-selected="false">
                    Change Password
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane fade show active" id="profile_settings" role="tabpanel">
                  <form method="post" class="needs-validation" enctype="multipart/form-data">
                    <div class="form-group">
                      <label>Username</label>

                      <input
                        type="text"
                        name="username"
                        class="form-control"
                        value={userName}
                        onChange={e => setUserName(e.target.value)}
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label>Phone</label>
                      <input
                        type="text"
                        name="phone"
                        onChange={e => setPhoneNumber(e.target.value)}
                        class="form-control"
                        value={phoneNumber}
                        required
                        maxlength="10"
                      />
                    </div>
                    <div class="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        class="form-control"
                        required
                      />
                    </div>
                    {/* <div class="form-group">
                      <label>Profile Image</label>
                      <div class="media align-items-center">
                        <div class="media-left">
                          <img
                            class="rounded-circle profile-img avatar-view-img"
                            src=""
                            alt=""
                            width="100"
                            height="100"
                            id="preview_img"
                          />
                        </div>
                        <div class="media-body">
                          <div class="uploader">
                            <input type="file" name="image" class="btn btn-secondary btn-sm ml-3" />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div class="mt-4 save-form">
                      <button
                        class="btn btn-primary save-btn"
                        name="update_profile"
                        type="submit"
                        onClick={e => onEdit(e)}
                      >
                        Edit Profile
                      </button>
                    </div>
                  </form>
                </div>
                <div class="tab-pane fade" id="change_password" role="tabpanel">
                  <form method="post" class="needs-validation" enctype="multipart/form-data">
                    <div class="form-group">
                      <label>Current Password</label>
                      <input
                        type="password"
                        name="old_pass"
                        class="form-control"
                        value={currentPassword}
                        onChange={e => setCurrentPassword(e.target.value)}
                      />
                    </div>
                    <div class="form-group">
                      <label>New Password</label>
                      <input
                        type="password"
                        name="new_pass"
                        class="form-control"
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                      />
                    </div>
                    <div class="mt-4 save-form">
                      <button class="btn save-btn btn-primary" name="cng_pass" onClick={onSubmit}>
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
    </>
  );
}
