import React from 'react';
import { useState } from 'react';
import HttpClient from '../utils/HttpClient';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

function Password() {
  // const [oldPassword, setoldPassword] = useState('');
  const [newPassword, setnewPassword] = useState('');

  const navigate = useNavigate();

  const { id } = useParams();
  console.log('ID', id);

  const onSubmit = async e => {
    e.preventDefault();
    let data = {
      // oldPassword: oldPassword,
      newPassword: newPassword,
      // userId: id,
    };

    console.log('Data', data);

    if (newPassword !== '') {
      let result = await HttpClient.requestData(`user/reset-password/${id}`, 'PUT', data);
      console.log('CHange', result);
      if (result && result.status) {
        toast.success(result.message, {
          style: {
            borderRadius: '10px',
            background: '#8297cd',
            color: 'FFF',
          },
        });
        navigate('/');
      } else {
        toast.error(result.message);
      }
    } else {
      toast.error('New Password Field Can not be Empty');
    }
  };

  return (
    <>
      <div className="fpwd">
        <div className="pass_form">
          <div className="pass_formtop">
            <h1>Reset Password</h1>
          </div>
          <div className="pass_formbtm">
            <form>
              {/* <input
                type="password"
                placeholder="Old Password"
                value={oldPassword}
                onChange={e => setoldPassword(e.target.value)}
              /> */}
              <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={e => setnewPassword(e.target.value)}
              />
              <button onClick={onSubmit}>Submit</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Password;
