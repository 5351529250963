import React from 'react';

const GeneralReport = () => {
  return (
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col">View Report</div>
            <div class="col-auto">
              <a href="add_household_profile.php" class="btn btn-primary">
                ADD
              </a>
              <i class="fas fa-plus"></i>
            </div>
          </div>
        </div>
        {/* <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <table id="example" class="table table-striped table-bordered" style={{ width: '100%' }}>
                    <thead>
                      <tr align="center">
                        <th>Sl.No.</th>
                        <th>Registered Date</th>
                        <th>Last Paid Date</th>
                        <th>Next Payment Due</th>
                        <th>User Name</th>
                        <th>Password</th>
                        <th>Account Type</th>
                        <th>User Type</th>
                        <th>Language Type</th>
                        <th>Pricing Type</th>
                        <th>Pricing Amount</th>
                        <th>Account Code</th>
                        <th>Promo Code</th>
                        <th>Promo Used</th>
                        <th>User Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr align="center">
                        <td>1</td>

                        <td>24628623</td>

                        <td>dbcjkc </td>

                        <td>c kcdn</td>
                        <td>ohdsac</td>
                        <td>dcbds</td>
                        <td>ugcs</td>
                        <td>udskjdsc</td>
                        <td>jkdsbsdj</td>

                        <td>kjsdbcjdc</td>

                        <td>jkdbcjksdcjks</td>

                        <td>jbdskj</td>

                        <td></td>

                        <td>
                          <a
                            href="edit_household_profile.php?id=<?php echo base64_encode($id); ?>"
                            class="btn btn-primary"
                          >
                            Edit
                          </a>
                        </td>
                        <td>
                          <a href="manage_household.php?id=<?php echo base64_encode($id);?>">
                            <i aria-hidden="true" class="fa fa-trash btn-lg"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default GeneralReport;
