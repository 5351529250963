import React, { useState } from 'react'

export default function Form(){
    return(
        <>
        <div class="page-wrapper">
			<div class="content container-fluid">
				<div class="page-header">
					<div class="row">
						<div class="col">
							<h3 class="page-title">Manage Type</h3>
						</div>
						<div class="col-auto text-right btn-12">
							<a href="view_sem.php" class="btn btn-primary btn-primary1" style={{width:"100%"}}>
								Manage
							</a> &emsp;&emsp;
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<div class="card">
							<div class="card-body">
								<div class="table-responsive">
								
							   <form method="post" enctype="multipart/form-data">
						         <span style={{color:"red"}}>All fields are required *</span>
						    
							<div class="form-group row">
							 
    							<div class="col-sm-6">
    							    <label for="input-5">Car name</label>
                                    <input type="text" class="form-control" name="cname" required placeholder="Car Name" />
                                </div>
                                
                                 <div class="col-sm-6">
							    <label for="input-5">Price Per km</label>
                                <input type="text" class="form-control" name="Price" id="phone" required placeholder="Price Per km" oninput="this.value=this.value.replace(/[^0-9 ]/g,'');"/>
							  </div>
							</div>							
							<div class="form-group row">
							  	<div class="col-sm-6">
    							    <label for="input-5">Car Image:</label>
                            <input type="file" class="form-control" required name="txtphoto" multiple="" id="file-ip-1" onchange="showPreview(event);" />
                            
                                </div>
                                <div class="col-sm-6">
                                    <div class="preview">
                                     <img id="file-ip-1-preview" src="user.png" />
                                    </div>
                                </div>
							</div>
							
							<div class="mt-4">
							  <button class="btn btn-primary" type="submit" name="submit">Submit</button>
							  <button class="btn btn-primary" type="reset">Cancel</button>
							</div>
						  </form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
        </>
    )
}