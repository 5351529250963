import React, { useEffect, useState } from 'react';
import HttpClient from '../utils/HttpClient';
import DataTable from 'react-data-table-component';
import moment from 'moment';

const SUbUserDetail= () => {
  const [data, setData] = useState([]);
  const [globeDetails, setGlobeDetails] = useState([]);

  const columns = [
    {
      name: 'SL NO.',
      selector: row => row.sl_no,
    },
    {
      name: 'REGISTERED DATE',
      selector: row => row.registeredDate,
    },
    {
      name: 'LAST PAID DATE',
      selector: row => row.lastPaidDate,
    },
    {
      name: 'NEXT PAYMENT DUE',
      selector: row => row.nextPaymentDue,
    },
    {
      name: 'USER NAME',
      selector: row => row.userName,
    },
    {
      name: 'PASSWORD',
      selector: row => row.password,
    },
    {
      name: 'ACOUNT TYPE',
      selector: row => row.acountType,
    },
    {
      name: 'USER TYPE',
      selector: row => row.userType,
    },
    {
      name: 'LANGUAGE TYPE',
      selector: row => row.languageType,
    },

    // {
    //   name: 'Notification Status',
    //   selector: row => row.notificationStatus,
    // },

    {
      name: 'PRICING TYPE',
      selector: row => row.pricingType,
    },

    {
      name: 'PRICING AMOUNT',
      selector: row => row.pricingAmount,
    },
    {
      name: 'ACOUNT CODE',
      selector: row => row.acountCode,
    },
    {
      name: 'PROMO CODE',
      selector: row => row.promoCode,
    },
    {
      name: 'PROMO USED',
      selector: row => row.promoUsed,
    },
    {
      name: 'USER STATUS',
      selector: row => row.userStatus,
    },
    {
      name: 'NO OF ACOUNT SUB USERS',
      selector: row => row.subUsers,
    },
    {
      name: 'NO OF INVITES',
      selector: row => row.noOfInvites,
    },
    {
      name: 'NO OF REGISTERED INVITES',
      selector: row => row.pricingAmount,
    },
    {
      name: 'CURRENT COMMISION DUE',
      selector: row => row.pricingAmount,
    },
    {
      name: 'TOTAL PAID COMMISION',
      selector: row => row.pricingAmount,
    },
  ];

  const globaluserdetailslist = async () => {
    const res = await HttpClient.requestData('admin/get-allUser', 'POST', {});
    // console.log('res', res);
    // if(res && res.status){
    //   setGlobeDetails(res)
    //   console.log(glo)
    // }
    console.log('globeDetails', res);
    if (res) {
      setGlobeDetails(res.data);
      let arr = [];
      res.data.forEach((item, i) => {
        arr.push({
          sl_no: i + 1,
          city: item.city,
          created_on: moment(item.createdOn).format('LL'),
          email: item.email,
          firstName: item.firstName,
          gender: item.gender,
          lastName: item.lastName,
          mobileNumber: item.mobileNumber,
          // notificationStatus: item.notificationStatus,
          state: item.state,
          // userType: item.userType,
          zipcode: item.zipCode,
          id: item._id,
        });
      });
      console.log('arrr', arr);
      setData(arr);
    }
  };

  useEffect(() => {
    globaluserdetailslist();
  }, []);
  return (
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col">View Global User Details</div>
            <div class="col-auto">
              {/* <a href="add_household_profile.php" class="btn btn-primary">
                ADD
              </a> */}
              {/* <i class="fas fa-plus"></i> */}
            </div>
          </div>
        </div>
        {/* {globeDetails && globeDetails.map((ele)=>(
          <div key={ele._id}>{ele.city}</div>
        ))} */}
        <DataTable columns={columns} data={data} pagination />;
      </div>
    </div>
  );
};

export default SUbUserDetail;
