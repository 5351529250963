import React, { useEffect } from 'react';
import { TextField, Button } from '@mui/material';
import HttpClient from '../utils/HttpClient';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import DataTable from 'react-data-table-component';

const CategoryMgmt = () => {
  const [categoryName, setCategoryName] = useState('');
  const [categoryData, setCategorydata] = useState([]);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState('');
  // const [error, setError] = useState(false);

  const onEdit = (categoryName, id) => {
    window.scrollTo(0, 0);
    setEdit(true);
    setId(id);
    setCategoryName(categoryName);
  };

  useEffect(() => {
    fetchCategoryData();
  }, []);

  const onDelete = async id => {
    let result = await HttpClient.requestData(`admin/delete-category/${id}`, 'PUT');
    console.log('Delete', result, id);
    if (result && result.status) {
      toast.success('Delete Successfully');
      fetchCategoryData();
    } else {
      toast.error('');
    }
  };

  const fetchCategoryData = async () => {
    let result = await HttpClient.requestData('admin/get-category', 'GET');
    console.log('fetch', result);
    if (result && result.status) {
      let arr = result.data.map((item, index) => {
        return {
          sl: index + 1,
          categoryName: item.categoryName,
          edit: (
            <div class="mt-4">
              <button
                class="btn btn-primary"
                type="submit"
                name="submit"
                onClick={() => onEdit(item.categoryName, item._id)}
              >
                Edit
              </button>
            </div>
          ),
          delete: (
            <div class="mt-4">
              <button class="btn btn-primary" type="submit" name="submit" onClick={() => onDelete(item._id)}>
                Delete
              </button>
            </div>
          ),
        };
      });
      setCategorydata(arr);
    }
  };

  const columns = [
    {
      name: 'Sl No',
      selector: row => row.sl,
    },
    {
      name: 'Category Name',
      selector: row => row.categoryName,
    },
    {
      name: 'Edit',
      selector: row => row.edit,
    },
    {
      name: 'Delete',
      selector: row => row.delete,
    },
  ];

  let data = {
    categoryName: categoryName,
  };
  const AddCategory = async e => {
    e.preventDefault();
    if (categoryName !== '') {
      setCategoryName('');
      let result = await HttpClient.requestData('admin/add-category', 'POST', data);
      console.log(result);
      if (result && result.status) {
        toast.success(result.message);
        fetchCategoryData();
      } else {
        toast.error(result.message);
      }
      console.log('Result', data);
    } else {
      toast.error('Category Name fields are required ');
    }
  };

  const EditCategory = async e => {
    e.preventDefault();
    let data = {
      categoryName: categoryName,
    };
    let reuslt = await HttpClient.requestData(`admin/edit-category/${id}`, 'PUT', data);
    if (reuslt && reuslt.status) {
      fetchCategoryData();
      toast.success(reuslt.message);
      setCategoryName('');
      setEdit(false);
    } else {
      toast.error(reuslt.message);
    }
    console.log('Result', data);
  };

  return (
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col">View Category Management</div>
            {/* <div class="col-auto">
              <a href="add_household_profile.php" class="btn btn-primary">
                ADD
              </a>
              <i class="fas fa-plus"></i>
            </div> */}
          </div>
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <TextField
              style={{ width: '50%', marginTop: '22px' }}
              id="filled-basic"
              label="Category Name"
              value={categoryName}
              variant="filled"
              onChange={e => setCategoryName(e.target.value)}
            />
            <Button style={{ width: '50%', marginTop: '22px' }} onClick={AddCategory} variant="contained">
              Add Category
            </Button>
          </div> */}
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <form method="post" enctype="multipart/form-data">
                      <div class="form-group row">
                        <div class="col-sm-6">
                          <label for="input-5">Category Name</label>
                          <input
                            type="text"
                            class="form-control"
                            value={categoryName}
                            onChange={e => setCategoryName(e.target.value)}
                            name="categoryName"
                            required
                            placeholder="CategoryName"
                          />
                        </div>
                      </div>
                      {edit ? (
                        <div class="mt-4">
                          <button class="btn btn-primary" type="submit" onClick={EditCategory} name="submit">
                            EditCategory
                          </button>
                        </div>
                      ) : (
                        <div class="mt-4">
                          <button class="btn btn-primary" type="submit" onClick={AddCategory} name="submit">
                            AddCategory
                          </button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5 style={{ marginTop: '22px' }}>Category Table</h5>
          <DataTable columns={columns} data={categoryData} pagination />
        </div>
      </div>
    </div>
  );
};

export default CategoryMgmt;
