import React, { useState } from 'react';
import HttpClient from '../../utils/HttpClient';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/UserContext';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-hot-toast';

export default function Login() {
  const Navigate = useNavigate();
  const { fetchUserData } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const handleEmailChange = e => {
    setEmail(e.target.value);
    // console.log(email)
  };
  const handlePasswordChange = e => {
    setPassword(e.target.value);
    // console.log(password)
  };
  const handleClick = async e => {
    e.preventDefault();
    const data = {
      email: email,
      password: password,
    };

    if (email !== '' && password !== '') {
      const loginResult = await HttpClient.requestData('admin/login', 'POST', data);
      if (loginResult && loginResult.status) {
        // reactLocalStorage.setItem('userData', loginResult.data);
        reactLocalStorage.set('userData', (loginResult?.data));
        toast.success(loginResult.message);
        fetchUserData();

        Navigate("/")
        // console.log('Loginresult', loginResult);
      } else {
        toast.error(loginResult.message);
      }
    } else {
      toast.error('All Fields are Required');
    }
  };
  return (
    <div class="main-wrapper">
      <div class="login-page">
        <div class="login-body container">
          <div class="loginbox">
            <div class="login-right-wrap">
              <div class="account-header">
                <div class="account-logo text-center mb-4">
                  <a href="index.php">
                    <h3>ADMIN</h3>
                  </a>
                </div>
              </div>
              <div class="login-header mt-20">
                <h3>
                  Login <span>ADMIN</span>
                </h3>
                <p class="text-muted">Access to our dashboard</p>
              </div>
              <form method="post">
                <div class="form-group">
                  <label class="control-label">Username</label>
                  <input
                    onChange={handleEmailChange}
                    class="form-control"
                    name="email"
                    type="EMAIL"
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <div class="form-group mb-4">
                  <label class="control-label">Password</label>
                  <input
                    onChange={handlePasswordChange}
                    class="form-control"
                    name="password"
                    type="password"
                    placeholder="Enter your password"
                    required
                  />
                </div>
                <div class="text-center">
                  <button
                    onClick={handleClick}
                    class="btn btn-primary btn-block account-btn"
                    name="submit"
                    type="submit"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
