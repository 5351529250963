import React, { useState } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { useAuth } from '../Context/UserContext';
import { redirect } from 'react-router-dom';

export default function Header() {
  const { fetchUserData } = useAuth();
  const handleLogout = () => {
    reactLocalStorage.clear();
    // fetchUserData();
    // redirect('/');
    window.location.pathname = '/';
  };
  return (
    <div class="header">
      <div class="header-left">
        <a href="index.php" class="logo logo-small">
          <img
            src={'https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg'}
            style={{ height: 30, width: 30 }}
          />
        </a>
      </div>
      <a href="javascript:void(0);" id="toggle_btn">
        <i class="fas fa-align-left"></i>
      </a>
      <a class="mobile_btn" id="mobile_btn" href="javascript:void(0);">
        <i class="fas fa-align-left"></i>
      </a>

      <ul class="nav user-menu">
        <li class="nav-item dropdown">
          <a href="javascript:void(0)" class="dropdown-toggle user-link  nav-link" data-toggle="dropdown">
            <span class="user-img">
              <img
                class="rounded-circle"
                src="https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg"
                width="40"
                alt="Admin"
              />
            </span>
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" href="/profile">
              Profile
            </a>
            <a class="dropdown-item" onClick={handleLogout}>
              Logout
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
}
