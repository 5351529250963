import React, { useState } from 'react';
import HttpClient from '../utils/HttpClient';
import { useLocation, useNavigate } from 'react-router-dom';
const UserContext = React.createContext(null);

export const useAuth = () => React.useContext(UserContext);

function UserContextProvider({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [loginStatus, setLoginStatus] = useState(false);
  const [userData, setUserData] = useState(null);

  const fetchUserData = async () => {
    let result = await HttpClient.requestData('admin/view', 'GET');

    if (result && result.success) {
      setLoginStatus(true);
      setUserData(result.data);
      if (location.pathname == '/login') {
        navigate('/');
        // console.log("location")
      }
    } else {
      navigate('/login');
    }
  };

  return (
    <UserContext.Provider
      value={{
        loginStatus,
        userData,
        fetchUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
