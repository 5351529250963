import React, { useEffect, useState } from 'react';
import HttpClient from '../utils/HttpClient';
import DataTable from 'react-data-table-component';
import moment from 'moment';

const PuUserDetail = () => {
  const [data, setData] = useState([]);
  const [globeDetails, setGlobeDetails] = useState([]);

  const columns = [
    {
      name: 'SL NO.',
      selector: row => row.sl_no,
    }, {
      name: 'Email',
      selector: row => row.email,
    },
    {
      name: 'User-Type',
      selector: row => row.userType,
    },
    // {
    //   name: 'First Name',
    //   selector: row => row.registeredDate,
    // },
    // {
    //   name: 'Last Name',
    //   selector: row => row.lastPaidDate,
    // },
    // {
    //   name: 'City',
    //   selector: row => row.nextPaymentDue,
    // },
    // {
    //   name: 'State',
    //   selector: row => row.userName,
    // },
    // {
    //   name: 'Country',
    //   selector: row => row.password,
    // },
    // {
    //   name: 'Currency',
    //   selector: row => row.acountType,
    // },
    // {
    //   name: 'Company Name',
    //   selector: row => row.userType,
    // },
    // {
    //   name: 'RFC ID',
    //   selector: row => row.languageType,
    // },

    // // {
    // //   name: 'Notification Status',
    // //   selector: row => row.notificationStatus,
    // // },

    // {
    //   name: 'Mobile No',
    //   selector: row => row.pricingType,
    // },

    // {
    //   name: 'Refferal Code',
    //   selector: row => row.pricingAmount,
    // },
    // {
    //   name: 'Account Reg Code',
    //   selector: row => row.acountCode,
    // },
    // {
    //   name: 'Type',
    //   selector: row => row.promoCode,
    // },
    // {
    //   name: 'Language Type',
    //   selector: row => row.promoUsed,
    // },
    // {
    //   name: 'View Manager',
    //   selector: row => row.userStatus,
    // },
    // {
    //   name: 'View Staff',
    //   selector: row => row.subUsers,
    // },
    // {
    //   name: 'Status',
    //   selector: row => row.noOfInvites,
    // },
  ];

  const globaluserdetailslist = async () => {
    const res = await HttpClient.requestData('admin/allPersonalUser', 'GET');
    if (res && res?.status) {
      setGlobeDetails(res.data);
      let arr = res?.data?.reverse()?.map((item, ind) => {
        console.log("df4g1f", item)
        return {
          sl_no: ind + 1,
          email: item?.email,
          userType: item?.userType,
        }
      });
      setData(arr);
    }
  };

  useEffect(() => {
    globaluserdetailslist();
  }, []);
  return (
    <div class="page-wrapper">
      <div class="content container-fluid">
        <div class="page-header">
          <div class="row">
            <div class="col">View Personal User Details</div>
            <div class="col-auto">
              {/* <a href="add_household_profile.php" class="btn btn-primary">
                ADD
              </a> */}
              {/* <i class="fas fa-plus"></i> */}
            </div>
          </div>
        </div>
        {/* {globeDetails && globeDetails.map((ele)=>(
          <div key={ele._id}>{ele.city}</div>
        ))} */}
        <DataTable columns={columns} data={data} pagination />
      </div>
    </div>
  );
};

export default PuUserDetail;
